import { useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import { maskCurrency, maskNumberOfInnstallments, maskPeriod } from '../utils/masks'

/*eslint-disable*/
const CreditoForm = ({ register, errors, watch, setValue }) => {
  // Somente Numeros
  const numeros = (evt) => {
    let theEvent = evt || window.event
    let key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    let regex = /^[0-9.,-/]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  const currencyValue = watch('ValorCreditoPretendido')
  useEffect(() => {
    setValue('ValorCreditoPretendido', maskCurrency(currencyValue))
  }, [currencyValue])

  const numberOfInnstallments = watch('NumeroDeParcelas')
  useEffect(() => {
    setValue('NumeroDeParcelas', maskNumberOfInnstallments(numberOfInnstallments))
  }, [numberOfInnstallments])

  const period = watch('Carencia')
  useEffect(() => {
    setValue('Carencia', maskPeriod(period))
  }, [period])

  return (
    <div className="creditoForm">
      <div className="grid">
        <div className="valorCredito">
          <TextField
            {...register('ValorCreditoPretendido', { required: true })}
            label="Valor de Crédito Pretendido"
            variant="filled"
            className="fieldStyle"
          />
          {errors.ValorCreditoPretendido && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <Box sx={{ maxWidth: 540 }} className="nmParcelas">
          <FormControl variant="filled" fullWidth className="fieldStyle">
            <TextField
              {...register('NumeroDeParcelas', { required: true })}
              label="Número de Parcelas"
              variant="filled"
              className="fieldStyle"
              // onChange={(event) => setNmParcelas(event.target.value.replace(/[a-zA-Z]/g, ''))}
              InputProps={{ inputProps: { maxLength: 3 } }}
            />
          </FormControl>
          {errors.NumeroDeParcelas && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </Box>

        <Box sx={{ maxWidth: 540 }} className="carencia">
          <FormControl variant="filled" fullWidth className="fieldStyle">
            <TextField
              {...register('Carencia', { required: true })}
              label="Carência"
              variant="filled"
              className="fieldStyle"
              // onChange={(event) => setCarencia(event.target.value.replace(/[a-zA-Z]/g, ''))}
              InputProps={{ inputProps: { maxLength: 2 } }}
            />
          </FormControl>
          {errors.Carencia && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </Box>

        <Box sx={{ maxWidth: 540 }} className="taxa">
          <FormControl variant="filled" fullWidth className="fieldStyle">
            <TextField
              {...register('Taxa', { required: true })}
              label="Taxa"
              variant="filled"
              className="fieldStyle"
              onChange={(event) => setValue('Taxa', event.target.value)}
            />
          </FormControl>
          {errors.Taxa && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </Box>

        <div className="creditoEmprego">
          <div className="fCredito">
            <FormControl className="finalidadeCredito radioStyle" style={{ borderRadius: '50px' }}>
              <FormLabel>Qual é a finalidade do crédito:</FormLabel>
              <RadioGroup className="finalidadeCreditoRadio"
                onChange={(e) => setValue('FinalidadeDoCredito', e.target.value)}
              >
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="0"
                  control={<Radio />}
                  label="Capital de Giro (compra de estoque, pagamento de fornecedores, etc.)"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="15"
                  control={<Radio />}
                  label="Pagamento 13º salário"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="1"
                  control={<Radio />}
                  label="Investimento (veículos, máquinas, equipamentos, ampliações, reformas, etc.)"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="16"
                  control={<Radio />}
                  label="Marketing"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="17"
                  control={<Radio />}
                  label="Tecnologia (computadores, sistemas, etc.)"
                />
              </RadioGroup>
            </FormControl>
            {errors.FinalidadeDoCredito && (
              <span className="erroForm">&gt; Obrigatório</span>
            )}
          </div>

          <div className="fEmpregos">
            <div className="quantosEmpregados">
              <TextField
                {...register('NumeroAtualFuncionario', { required: true })}
                label="Quantos empregados a empresa possui?"
                variant="filled"
                className="fieldStyle"
                onKeyPress={numeros}
              />
              {errors.NumeroAtualFuncionario && (
                <span className="erroForm">&gt; Obrigatório</span>
              )}
            </div>

            <div className="quantosEmpregos">
              <TextField
                {...register('ProvaveisEmpregos', { required: true })}
                label="Serão gerados novos empregos? Quantos?"
                variant="filled"
                className="fieldStyle"
                onKeyPress={numeros}
              />
              {errors.ProvaveisEmpregos && (
                <span className="erroForm">&gt; Obrigatório</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditoForm;