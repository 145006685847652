import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { TextField, Button } from '@mui/material'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import MenuItem from '@mui/material/MenuItem'
import { useFieldArray } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

const SocioForm = ({ register, watch, control, errors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socios',
    })

    const add = () => {
        append({
            Cpf: '',
            Nome: '',
            Email: '',
            Telefone: '',
            DataDeNascimento: '',
            NomeDaMae: '',
            Rg: '',
            Sexo: '',
            Naturalidade: '',
            Cep: '',
            Endereco: '',
            Bairro: '',
            Estado: '',
            Cidade: '',
            Participacao: '',
            EstadoCivil: '',
            CpfConjuge: '',
            NomeConjuge: '',
            EmailConjuge: '',
            TelefoneConjuge: '',
            DataDeNascimentoConjuge: '',
            NomeDaMaeConjuge: '',
            RgConjuge: '',
            SexoConjuge: '',
            NaturalidadeConjuge: '',
            CepConjuge: '',
            EnderecoConjuge: '',
            BairroConjuge: '',
            EstadoConjuge: '',
            CidadeConjuge: '',
        })
    }

    //Somente Numeros no input
    const numeros = (evt) => {
        let theEvent = evt || window.event
        let key = theEvent.keyCode || theEvent.which
        key = String.fromCharCode(key)
        //let regex = /^[0-9.,]+$/;
        let regex = /^[0-9.,-/]+$/
        if (!regex.test(key)) {
            theEvent.returnValue = false
            if (theEvent.preventDefault) theEvent.preventDefault()
        }
    }

    //validação de preenchimento
    const obrigatorio = { required: false }

    return (
        <div>
            {fields.map((socio, index) => {
                const estadoCivil = watch(`socios.${index}.EstadoCivil`)

                //////MASCARAS DE INPUTS SÓCIOS\\\\\\
                //Mascara CPF e Função Somente Numeros
                const pfScCpfInput = (evt) => {
                    const input = document.querySelector(`#pfScCpf${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 3 || inputLength === 7) {
                        input.value += '.'
                    } else if (inputLength === 11) {
                        input.value += '-'
                    }
                }

                //Mascara Telefone e Função Somente Numeros
                const pfScTelefoneInput = (evt) => {
                    const input = document.querySelector(`#pfScTelefone${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    //let regex = /^[0-9.,]+$/;
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 0) {
                        input.value += '('
                    } else if (inputLength === 3) {
                        input.value += ')'
                    }
                }

                //Mascara Nascimento e Função Somente Numeros
                const pfScNascimentoInput = (evt) => {
                    const input = document.querySelector(`#pfScNascimento${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    //let regex = /^[0-9.,]+$/;
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 2 || inputLength === 5) {
                        input.value += '/'
                    }
                }

                //Mascara CEP e Função Somente Numeros
                const pfScCepInput = (evt) => {
                    const input = document.querySelector(`#pfScCep${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    //let regex = /^[0-9.,]+$/;
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 5) {
                        input.value += '-'
                    }
                }
                //////MASCARAS DE INPUTS CONJUGE SÓCIOS\\\\\\
                //Mascara CPF e Função Somente Numeros
                const pfCjScCpfInput = (evt) => {
                    const input = document.querySelector(`#pfCjScCpf${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    //let regex = /^[0-9.,]+$/;
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 3 || inputLength === 7) {
                        input.value += '.'
                    } else if (inputLength === 11) {
                        input.value += '-'
                    }
                }

                //Mascara Telefone e Função Somente Numeros
                const pfCjScTelefoneInput = (evt) => {
                    const input = document.querySelector(`#pfCjScTelefone${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    //let regex = /^[0-9.,]+$/;
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 0) {
                        input.value += '('
                    } else if (inputLength === 3) {
                        input.value += ')'
                    }
                }

                //Mascara Nascimento e Função Somente Numeros
                const pfCjScNascimentoInput = (evt) => {
                    const input = document.querySelector(`#pfCjScNascimento${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 2 || inputLength === 5) {
                        input.value += '/'
                    }
                }

                //Mascara CEP e Função Somente Numeros
                const pfCjScCepInput = (evt) => {
                    const input = document.querySelector(`#pfCjScCep${index}`)
                    let inputLength = input.value.length
                    let theEvent = evt || window.event
                    let key = theEvent.keyCode || theEvent.which
                    key = String.fromCharCode(key)
                    let regex = /^[0-9]+$/
                    if (!regex.test(key)) {
                        theEvent.returnValue = false
                        if (theEvent.preventDefault) theEvent.preventDefault()
                    } else if (inputLength === 5) {
                        input.value += '-'
                    }
                }

                return (
                    <div key={socio.id}>
                        <div className="pfForm">
                            <div className="socioTitle">
                                <h2>Sócio {index + 1} / Pessoa Física</h2>
                            </div>

                            <div className="exclusaoSocio">
                                <Button
                                    variant="contained"
                                    onClick={() => remove(index)}
                                    className="excluiSocio"
                                >
                                    REMOVER
                                </Button>
                            </div>

                            <div className="grid">
                                <div className="cpfSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Cpf`, obrigatorio)}
                                        label="CPF do Sócio"
                                        id={`pfScCpf${index}`}
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={pfScCpfInput}
                                        inputProps={{
                                            maxLength: 14,
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Cpf`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="nomeSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Nome`, obrigatorio)}
                                        label="Nome do Sócio"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Nome`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="emailSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Email`, obrigatorio)}
                                        label="E-mail do Sócio"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Email`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="telefoneSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Telefone`, obrigatorio)}
                                        label="Telefone do Sócio"
                                        id={`pfScTelefone${index}`}
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={pfScTelefoneInput}
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Telefone`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="nascimentoSocio1">
                                    <TextField
                                        {...register(
                                            `socios.${index}.DataDeNascimento`,
                                            obrigatorio,
                                        )}
                                        label="Data de Nascimento"
                                        id={`pfScNascimento${index}`}
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={pfScNascimentoInput}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.DataDeNascimento`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="nomemaeSocio1">
                                    <TextField
                                        {...register(`socios.${index}.NomeDaMae`, obrigatorio)}
                                        label="Nome da Mãe"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.NomeDaMae`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="rgSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Rg`, obrigatorio)}
                                        label="RG"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={numeros}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Rg`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <Box sx={{ maxWidth: 540 }} className="sexoSocio1">
                                    <FormControl
                                        variant="filled"
                                        fullWidth
                                        className="fieldStyle"
                                    >
                                        <InputLabel>Sexo</InputLabel>
                                        <Select
                                            {...register(`socios.${index}.Sexo`, obrigatorio)}
                                            label="Sexo"
                                        >
                                            <MenuItem
                                                value={undefined}
                                                disabled
                                                style={{ display: 'none' }}
                                            >
                                                selecione
                                            </MenuItem>
                                            <MenuItem value="0">Masc</MenuItem>
                                            <MenuItem value="1">Fem</MenuItem>
                                            <MenuItem value="2">Outro</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Sexo`}
                                        render={() => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </Box>

                                <div className="naturalidadeSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Naturalidade`, obrigatorio)}
                                        label="Naturalidade"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Naturalidade`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="cepSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Cep`, obrigatorio)}
                                        label="CEP"
                                        autoComplete="none"
                                        id={`pfScCep${index}`}
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={pfScCepInput}
                                        inputProps={{
                                            maxLength: 9,
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Cep`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="enderecoSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Endereco`, obrigatorio)}
                                        label="Endereço"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Endereco`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="bairroSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Bairro`, obrigatorio)}
                                        label="Bairro"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Bairro`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="estadoSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Estado`, obrigatorio)}
                                        label="Estado"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Estado`}
                                        render={() => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="cidadeSocio2">
                                    <TextField
                                        {...register(`socios.${index}.Cidade`, obrigatorio)}
                                        label="Cidade"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Cidade`}
                                        render={() => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="complementoSocio1">
                                    <TextField
                                        {...register(`socios.${index}.Complemento`, obrigatorio)}
                                        label="Complemento"
                                        autoComplete="none"
                                        variant="filled"
                                        className="fieldStyle"
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Complemento`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <div className="sociedadeSocio2">
                                    <TextField
                                        {...register(`socios.${index}.Participacao`, obrigatorio)}
                                        label="% Sociedade"
                                        variant="filled"
                                        className="fieldStyle"
                                        onKeyPress={numeros}
                                        inputProps={{
                                            maxLength: 2,
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`socios.${index}.Participacao`}
                                        render={({ message }) => (
                                            <span className="erroForm">&gt; Obrigatório</span>
                                        )}
                                    />
                                </div>

                                <FormControl className="estadoCivilSocio1 radioStyle BorderRadiusEstadoCivil">
                                    <FormLabel>Estado Civil</FormLabel>
                                    <RadioGroup className="estadoCivilRadio" row defaultValue="1">
                                        <FormControlLabel
                                            {...register(`socios.${index}.EstadoCivil`, {
                                                required: true,
                                            })}
                                            value="1"
                                            control={<Radio />}
                                            label="Solteiro(a)"
                                        />
                                        <FormControlLabel
                                            {...register(`socios.${index}.EstadoCivil`, {
                                                required: true,
                                            })}
                                            value="0"
                                            control={<Radio />}
                                            label="Casado(a)"
                                        />
                                        <FormControlLabel
                                            {...register(`socios.${index}.EstadoCivil`, {
                                                required: true,
                                            })}
                                            value="5"
                                            control={<Radio />}
                                            label="União Estável"
                                        />
                                        <FormControlLabel
                                            {...register(`socios.${index}.EstadoCivil`, {
                                                required: true,
                                            })}
                                            value="3"
                                            control={<Radio />}
                                            label="Divorciado(a)"
                                        />
                                        <FormControlLabel
                                            {...register(`socios.${index}.EstadoCivil`, {
                                                required: true,
                                            })}
                                            value="2"
                                            control={<Radio />}
                                            label="Viúvo(a)"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <div className="conjugeFields">
                                    {(estadoCivil === 0 || estadoCivil === 5) && (
                                        <div className="conjugeTitle">
                                            <h2>Cônjuge Sócio {index + 1} / Pessoa Física</h2>

                                            <div className="grid">
                                                <div className="cpfSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.CpfConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        id={`pfCjScCpf${index}`}
                                                        autoComplete="none"
                                                        label="CPF Cônjuge"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={pfCjScCpfInput}
                                                        inputProps={{
                                                            maxLength: 14,
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.CpfConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="nomeSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.NomeConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Nome Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.NomeConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="emailSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.EmailConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="E-mail Cônjuge"
                                                        autoComplete="none"
                                                        type="email"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.EmailConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="telefoneSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.TelefoneConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Telefone Cônjuge"
                                                        id={`pfCjScTelefone${index}`}
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={pfCjScTelefoneInput}
                                                        inputProps={{
                                                            maxLength: 13,
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.TelefoneConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="nascimentoSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.DataDeNascimentoConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Nascimento Cônjuge"
                                                        id={`pfCjScNascimento${index}`}
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={pfCjScNascimentoInput}
                                                        inputProps={{
                                                            maxLength: 10,
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.DataDeNascimentoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="nomemaeSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.NomeDaMaeConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Nome da Mãe Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.NomeDaMaeConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="rgSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.RgConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="RG Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={numeros}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.RgConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <Box sx={{ maxWidth: 540 }} className="sexoSocio1">
                                                    <FormControl
                                                        variant="filled"
                                                        fullWidth
                                                        className="fieldStyle"
                                                    >
                                                        <InputLabel>Sexo Cônjuge</InputLabel>
                                                        <Select
                                                            {...register(
                                                                `socios.${index}.SexoConjuge`,
                                                                obrigatorio,
                                                            )}
                                                            label="Sexo"
                                                        >
                                                            <MenuItem
                                                                value={undefined}
                                                                disabled
                                                                style={{ display: 'none' }}
                                                            >
                                                                selecione
                                                            </MenuItem>
                                                            <MenuItem value="0">Masc</MenuItem>
                                                            <MenuItem value="1">Fem</MenuItem>
                                                            <MenuItem value="2">Outro</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.SexoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </Box>

                                                <div className="naturalidadeSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.NaturalidadeConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Naturalidade Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.NaturalidadeConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="cepSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.CepConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="CEP Cônjuge"
                                                        id={`pfCjScCep${index}`}
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={pfCjScCepInput}
                                                        inputProps={{
                                                            maxLength: 9,
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.CepConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="enderecoSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.EnderecoConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Endereço Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.EnderecoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="bairroSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.BairroConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Bairro Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.BairroConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="estadoSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.EstadoConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Estado Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.EstadoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="cidadeSocio2">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.CidadeConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Cidade Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.CidadeConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="complementoSocio1">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.ComplementoConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Complemento Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.ComplementoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="profissaoCj">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.ProfissaoConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Profissão Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.ProfissaoConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>

                                                <div className="rendaCj">
                                                    <TextField
                                                        {...register(
                                                            `socios.${index}.RendaConjuge`,
                                                            obrigatorio,
                                                        )}
                                                        label="Renda Cônjuge"
                                                        autoComplete="none"
                                                        variant="filled"
                                                        className="fieldStyle"
                                                        onKeyPress={numeros}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`socios.${index}.RendaConjuge`}
                                                        render={({ message }) => (
                                                            <span className="erroForm">&gt; Obrigatório</span>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <Button variant="contained" onClick={add} className="addSocio">
                Incluir Sócio
            </Button>
        </div>
    )
}

export default SocioForm;