import './assets/css/App.css';
import Form from './components/Form';

const App = () => {
  return (
    <>
      <Form />
    </>
  );
}

export default App;