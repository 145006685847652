/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react';

const BcxSectionF = () => {

    const accordionElement = useRef();
    const faAngleRotate = useRef();
    const handleAccordion = () => {
        if (!accordionElement.current.classList.contains("accordion") && !faAngleRotate.current.classList.contains("rotateAngle")) {
            accordionElement.current.classList.add("accordion")
            faAngleRotate.current.classList.add("rotateAngle")
        } else {
            accordionElement.current.classList.remove("accordion")
            faAngleRotate.current.classList.remove("rotateAngle")
        }
    }

    return (
        <>
            <section id="sectionF" style={{paddingTop: '61.2px'}}>
                <header className="title">
                    <span
                        style={
                            {
                                display: 'block',
                                textAlign: 'center',
                                borderBottom: '3px solid #fff',
                                padding: '15px',
                                fontSize: '1.2rem',
                                color: '#fff',
                                position: 'fixed',
                                top: '0',
                                right: '0',
                                left: '0',
                                backgroundColor: '#004D29',
                                zIndex: 10000
                            }
                        }
                    >
                        Área exclusiva para cadastramento de proposta
                    </span>
                    <h2>
                        <strong>Documentação</strong>
                        <br />
                        necessária
                    </h2>
                </header>
                <div className="containerF">
                    <article>
                        <p>
                            • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
                            casadas ou com união estável devem apresentar também
                            documentação do cônjuge. Cópias devem ser coloridas.
                        </p>
                        <br />
                        <dl ref={accordionElement}>
                            <dt ref={faAngleRotate} onClick={handleAccordion}>Dados para envio:
                                <i><FontAwesomeIcon icon={faAngleUp} /></i>
                            </dt>
                            <dd>
                                <a href='https://solucoes.receita.fazenda.gov.br/servicos/certidaointernet/pj/emitir' target='_blank' rel="noreferrer">CND Federal PJ</a>
                            </dd>
                            <dd>
                                <a href='https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PF/Emitir' target='_blank' rel="noreferrer">CND Federal PF</a>
                            </dd>
                            <dd>
                                <a href='https://www.sefaz.rs.gov.br/sat/CertidaoSitFiscalSolic.aspx' target='_blank' rel="noreferrer">CND Estadual</a>
                            </dd>
                            <dd>
                                <a href='https://consulta-crf.caixa.gov.br/consultacrf/pages/consultaEmpregador.jsf' target='_blank' rel="noreferrer">Certidão FGTS</a>
                            </dd>
                            <dd>
                                <a href='https://cadastur.turismo.gov.br/hotsite/#!/public/sou-prestador/inicio' target='_blank' rel="noreferrer">Cadastur</a>
                            </dd>
                            <dd>
                                <a href='http://www.rais.gov.br/sitio/recibo_identificacao.jsf' target='_blank' rel="noreferrer">Recibo da RAIS</a>
                            </dd>
                            <dd>
                                <a href='http://consultacadastral.inss.gov.br/Esocial/pages/index.xhtml' target='_blank' rel="noreferrer">e-Social</a>
                            </dd>
                            <dd>
                                <a href='https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/cnpjreva_Solicitacao.asp' target='_blank' rel="noreferrer">CONSULTA CNPJ</a>
                            </dd>
                        </dl>
                        <br />
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Mais documentos abaixo, conforme tipo de empresa:</span>
                        </p>
                        <br />

                        <p>
                            <span style={{ fontWeight: 'bold' }}>• Se empresa enquadrada como MEI:</span>
                            <ul style={{ listStyle: 'none' }}>
                                <li>
                                    - CCMEI: Certificado de Condição de Microempreendedor
                                    Individual
                                </li>
                                <li>- DASN SIMEI: Declaração Anual do SIMEI</li>
                            </ul>
                        </p>
                        <br />

                        <p>
                            <span style={{ fontWeight: 'bold' }}>• Se empresa enquadrada como ME / EPP:</span>
                            <ul style={{ listStyle: 'none' }}>
                                <li>- Declaração de Firma Individual ou Contrato Social</li>
                                <li>
                                    - Empresa do Simples Nacional: PGDAS-D. Programa Gerador do Documento de Arrecadação do
                                    Simples Nacional Declaratório atualizado, com faturamento dos
                                    últimos 12 meses
                                </li>

                                <li>
                                    - Empresa do Lucro Presumido:
                                    SPED Contábil | Recibo, Balanço e DRE
                                </li>
                            </ul>
                        </p>
                    </article>
                    <article>

                    </article>
                </div>
            </section>
        </>
    )
}

export default BcxSectionF