
const TermosForm = ({ register, errors }) => {

  return (
    <div className="acceptTerms">

      <label
        style={{ color: '#fff' }}
        htmlFor='historico'
      >Histórico da empresa / Faturamento / Principais atividades realizadas atualmente:</label>
      <textarea
        id='historico'
        name="Historico"
        rows={8} cols={40}
        style={
          {
            padding: '10px',
            width: '100%',
            resize: 'none',
            display: 'block',
            fontSize: '1rem',
            boxShadow: '0 0 0',
            outline: 'none',
            border: 'none',
            borderRadius: '4px'
          }
        }
        {...register('Historico', { required: true })}
      >
      </textarea>
      {errors.Historico && (<span className='erroForm'>&gt; Obrigatório</span>)}
    </div>
  )
}

export default TermosForm;