const toBase64 = file =>
    new Promise((resolve, reject) => { 
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })

const UploadForm = ({ register, setValue, errors, props }) => {
    return (
        <div className="fileUpload">
            <label htmlFor="fileupload">
                {props}
            </label>
            {errors.files && <span className="erroForm">&gt; Obrigatório</span>}

            <div className="uploadAttachment">
                <input
                    {...register('files', { required: true })}
                    type="file"
                    id="fileupload"
                    name="file"
                    className="fieldStyle"
                    accept="application/pdf"
                    
                    onChange={event => {
                        const files = event.target.files || [];
                        if (files.length > 0) {
                            toBase64(files[0]).then(b64 => {
                                setValue('Base64DoArquivo', b64)
                                setValue('NomeDoArquivo', files[0].name)
                            })
                        }
                    }}
                />
            </div>
            <small>Anexe aqui seu documento (Tamanho Máximo: 2MB)</small>
        </div>
    )
}

export default UploadForm;