const agencies = [
    { id: 3136, name: "Porto Alegre - Porto Alegre", financialInstitution: 7, institution: 'Badesul' },

    { id: 448, name: "Porto Alegre - Porto Alegre", financialInstitution: 0, institution: 'BRDE' },
    
    { id: null, name: "Alto Feliz - Alto Feliz", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 1604, name: "Canela - Canela ", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 437, name: "Caxias do Sul - Ana Rech", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 150, name: "Caxias do Sul - Pio X", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 434, name: "Caxias do Sul - Cruzeiro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 396, name: "Caxias do Sul - Vinte de Setembro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 439, name: "Caxias do Sul - Lourdes", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 405, name: "Caxias do Sul - Rio Branco", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 451, name: "Caxias do Sul - Desvio Rizzo", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 446, name: "Caxias do Sul - Vila Cristina", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 444, name: "Caxias do Sul - Santa Lúcia do Piaí", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4399, name: "Caxias do Sul - Sanvitto", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4404, name: "Caxias do Sul - Santa Lúcia ", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4429, name: "Caxias do Sul - Galópolis", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4435, name: "Caxias do Sul - Forqueta", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 468, name: "Dois Irmãos - Dois Irmãos", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4470, name: "Dois Irmãos - São Miguel", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 176, name: "Estância Velha - Centro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 470, name: "Estância Velha - Rincão", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4430, name: "Feliz - Feliz", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 461, name: "Gramado - Centro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 1738, name: "Gramado - Várzea Grande", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Gramado - São Pedro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 177, name: "Ivoti - Ivoti", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Lindolfo Collor - Lindoldo Collor", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Linha Nova - Linha Nova", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 472, name: "Morro Reuter - Morro Reuter", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Nova Petrópolis - Sede", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 464, name: "Nova Petrópolis - Nova Petrópolis", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Nova Petrópolis - Linha Imperial", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Nova Petrópolis - Pinhal Alto", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Novo Hamburgo - Centro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4451, name: "Novo Hamburgo - Ouro Branco", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 1628, name: "Novo Hamburgo - Canudos", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 465, name: "Novo Hamburgo - Feevale", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 471, name: "Novo Hamburgo - Lomba Grande", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 486, name: "Picada Café - Picada Café", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 1743, name: "Portão - Portão", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4459, name: "Portão - Avenida Brasil", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 2848, name: "Presidente Lucena - Presidente Lucena ", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 155, name: "Santa Maria do Herval - Santa Maria do Herval", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "São Francisco de Paula - São Francisco de Paula", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "São José do Hortêncio - São José do Hortêncio", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 473, name: "São Leopoldo - Centro", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 3353, name: "São Leopoldo - Unisinos", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 466, name: "São Leopoldo - Scharlau", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4413, name: "São Leopoldo - Rio Branco", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: 4471, name: "São Leopoldo - Feitoria", financialInstitution: 5, institution: 'Sicredi Pioneira' },
    { id: null, name: "Vale Real - Vale Real", financialInstitution: 5, institution: 'Sicredi Pioneira' },

    { id: null, name: "Carlos Barbosa - Sede", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Barão - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 455, name: "Bento Gonçalves - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bento Gonçalves - Cidade Alta", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bento Gonçalves - São Roque", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bento Gonçalves - Agro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bento Gonçalves - Licorsul", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Boa Vista do Sul - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bom Principio - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Bom Principio - Santa Teresinha", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Carlos Barbosa - Ivo Tramontina - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Carlos Barbosa - Mauricio Cardoso - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Carlos Barbosa - Sicredi Empresas - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Coronel Pilar  - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Carlos Barbosa - Flores da Cunha", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Cotiporã - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Fagundes Varela - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 460, name: "Farroupilha - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Farroupilha - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Farroupilha - Agronegócio - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 449, name: "Flores da Cunha - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Flores da Cunha - São Gotardo", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Flores da Cunha - Otávio Rocha", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Flores da Cunha - Flores da Cunha - Agro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 4496, name: "Garibaldi - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Garibaldi - Dante Grossi - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Monte Belo do Sul - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Nova Pádua - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Nova Roma do Sul - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Pinto Banceira - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Santa Tereza - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 3333, name: "São Marcos - Feijó - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "São Marcos - Venâncio Aires - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "São Pedro da Serra - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "São Sebastião do Caí - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "São Vandelino - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Tupandi - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: 438, name: "Veranólopis - Veranólopis", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Veranólopis - Agência Sustentável - Palungana ", financialInstitution: 19, institution: 'Sicredi Serrana' },
    { id: null, name: "Vila Flores - Centro", financialInstitution: 19, institution: 'Sicredi Serrana' },

    { id: 525, name: "Caxias - 0202 Cooperucs", financialInstitution: 20, institution: 'Sicredi Cooperucs' },

    { id: 2786, name: "Santo Antônio da Patrulha - Santo Antônio da Patrulha", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 1727, name: "Taquara - Taquara", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4465, name: "Riozinho - Riozinho", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2966, name: "Osório - Osório", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 1726, name: "Parobé - Parobé", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2776, name: "Terra de Areia - Terra de Areia", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4370, name: "Nova Hartz - Nova Hartz ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2839, name: "Itati - Itati", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 1733, name: "Rolante - Rolante ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 1722, name: "Palmares do Sul - Palmares do Sul", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2768, name: "Torres - Torres", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4371, name: "Três Coroas - Três Coroas", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2976, name: "Capão da Canoa - Capão da Canoa", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 3071, name: "Campo Bom - Campo Bom", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4468, name: "Três Cachoeiras - Três Cachoeiras", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2777, name: "Igrejinha - Igrejinha", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2787, name: "Capivari do Sul - Capivari do Sul", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 2770, name: "Sapiranga - Sapiranga ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4406, name: "Tramandaí - Tramandaí", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 3346, name: "Maquiné - Maquiné ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4467, name: "Arroio do Sal - Arroio do Sal ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4466, name: "Mostardas - Mostardas", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4450, name: "Imbé - Imbé", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: null, name: "Balneário Pinhal - Balneário Pinhal ", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 4449, name: "Xangri-lá - Xangri-lá", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: null, name: "Rolante - Digital", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },
    { id: 1733, name: "Rolante - Sicredi Empresas", financialInstitution: 21, institution: 'Sicredi Caminho das Águas' },

    { id: 4437, name: "Nova Santa Rita - Nova Santa Rita - Centro", financialInstitution: 22, institution: 'Sicredi Ouro Branco' },

    { id: 4456, name: "Santa Maria - de Camobi", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: 4452, name: "Santa Maria - Centro", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: 4454, name: "Santa Maria - Parque Industrial", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: 4455, name: "Santa Maria - Dores", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: 4457, name: "Santa Maria - Avenida N Sra. Medianeira", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: 4453, name: "Santa Maria - Praça Nova", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Itaara - Itaara", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "São Martinho da Serra - São Martinho da Serra", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "São Pedro do Sul - São Pedro do Sul", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Toropi - Toropi", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Caçapava do Sul - Caçapava do Sul", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Lavras do Sul - Lavras do Sul ", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Santana da Boa Vista - Santana da Boa Vista", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Piratini - Piratini", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "São João do Polêsine - São João do Polêsine", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Faxinal do Soturno - Faxinal do Soturno", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Dona Francisca - Dona Francisca", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Nova Palma - Nova Palma", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "São Sepé - São Sepé", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Vila Nova do Sul - Vila Nova do Sul", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Restinga Sêca - Restinga Sêca", financialInstitution: 23, institution: 'Sicredi Região Centro' },
    { id: null, name: "Formigueiro - Formigueiro", financialInstitution: 23, institution: 'Sicredi Região Centro' },

    { id: 4458, name: "Santa Maria - Santa Maria", financialInstitution: 9, institution: 'Unicred' },
    { id: null, name: "Santa Maria - Sala de Negócio Unimed", financialInstitution: 9, institution: 'Unicred' },
    { id: null, name: "Santa Maria - Sala de Negócio Shopping Praça Nova", financialInstitution: 9, institution: 'Unicred' },
    { id: null, name: "Santa Maria - Sala de Negócios HCCA - Complexo Hospitalar Astrogildo de Azevedo", financialInstitution: 9, institution: 'Unicred' },

    { id: 4411, name: "Caxias do Sul - Caxias do Sul - Centro", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Farroupilha - Farroupilha", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "São Marcos - São Marcos", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: 4475, name: "Flores da Cunha - Flores da Cunha", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Otávio Rocha - Otávio Rocha", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: 4417, name: "Erechim - Erechim", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Passo Fundo - Passo Fundo ", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Barão de Cotegipe - Barão de Cotegipe", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Três Arroios - Três Arroios", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },
    { id: null, name: "Paulo Bento - Paulo Bento", financialInstitution: 12, institution: 'Cresol Centro Sul RS/MS' },

    { id: 4486, name: "Camaquã - Centro", financialInstitution: 24, institution: 'Cresol Planalto Serra' },

    { id: 146, name: "Caxias do Sul - Centro", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: 3001, name: "Caxias do Sul - Ana Rech", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: 3000, name: "Caxias do Sul - Cruzeiro", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: null, name: "Caxias do Sul - Santa Catarina", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: null, name: "Bento Gonçalves - Bento Gonçalves", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: 3002, name: "Bento Gonçalves - Osvaldo Aranha Bento Gonçalves", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: 3044, name: "Lajeado - Lajeado", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },
    { id: 1742, name: "Estrela - Estrela", financialInstitution: 25, institution: 'Sicoob - Unicoob Meridional' },

    { id: null, name: "Caxias do Sul - São Pelegrino", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: 458, name: "Caxias do Sul - Caxias do Sul", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Caxias do Sul - Lourdes", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: 459, name: "Bento Gonçalves - Bento Gonçalves", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: 463, name: "Farroupilha - Farroupilha", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: 469, name: "Flores da Cunha - Flores da Cunha", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: 482, name: "Porto Alegre - Porto Alegre", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Porto Alegre - Praia de Belas", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Porto Alegre - Floresta", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Porto Alegre - Otto Niemeyer", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Porto Alegre - Passo da Areia", financialInstitution: 26, institution: 'Sicoob Videira' },
    { id: null, name: "Porto Alegre - São João", financialInstitution: 26, institution: 'Sicoob Videira' },

    { id: 152, name: "Passo Fundo - Centro", financialInstitution: 27, institution: 'Sicoob Creditaipu' },
    { id: 3251, name: "Passo Fundo - São Cristóvão", financialInstitution: 27, institution: 'Sicoob Creditaipu' },
    { id: 153, name: "Marau - Marau", financialInstitution: 27, institution: 'Sicoob Creditaipu' },
    { id: 154, name: "Carazinho - Carazinho", financialInstitution: 27, institution: 'Sicoob Creditaipu' },
    { id: 1732, name: "Panambi - Panambi", financialInstitution: 27, institution: 'Sicoob Creditaipu' },

    { id: 1612, name: "Novo Hamburgo - Novo Hamburgo", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 1611, name: "Três Coroas - Três Coroas", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 1629, name: "Igrejinha - Igrejinha", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 1740, name: "Sapiranga - Sapiranga", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 1723, name: "Ivoti - Ivoti", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 1725, name: "Gramado - Gramado", financialInstitution: 28, institution: 'Sicoob Maxxicred' },
    { id: 3003, name: "São Francisco de Paula - São Francisco de Paula", financialInstitution: 28, institution: 'Sicoob Maxxicred' },

    { id: 2824, name: "Novo Prata - Novo Prata", financialInstitution: 31, institution: 'Sicoob São Miguel do Oeste/SC' },
    { id: 3044, name: "Lajeado - Lajeado", financialInstitution: 31, institution: 'Sicoob São Miguel do Oeste/SC' },
    { id: 3147, name: "Guaporé - Guaporé", financialInstitution: 31, institution: 'Sicoob São Miguel do Oeste/SC' },

    { id: null, name: "Porto Alegre - Passo da Areia", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Jardim Botâni", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Cristo Redentor", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: 3325, name: "Porto Alegre - Av. Assis Brasil- Cristo Redentor", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: 4383, name: "Porto Alegre - Azenha", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Centro Histórico", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Menino Deus ", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Rio Branco", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: 4372, name: "Porto Alegre - Sarandi", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Cristal", financialInstitution: 29, institution: 'Sicoob Credicapital' },
    { id: null, name: "Porto Alegre - Três Figueiras", financialInstitution: 29, institution: 'Sicoob Credicapital' },

    { id: 3050, name: "Gravataí - Gravataí", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: 3348, name: "Gravataí - Sicoob Três Fronteiras Sindilojas", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: 4414, name: "Gravataí - Morada dos Vales", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: 3051, name: "Canoas - Canoas Centro", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: null, name: "Canoas - Canoas CICS", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: 4403, name: "Canoas  - Mathias Velho", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },
    { id: 3134, name: "Cachoeirinha - Cachoeirinha", financialInstitution: 30, institution: 'Sicoob Três Fronteiras' },

    { id: 4491, name: "Caxias do Sul - Caxias do Sul", financialInstitution: 18, institution: 'Banco da Família' },
    { id: 4502, name: "Vacaria - Vacaria", financialInstitution: 18, institution: 'Banco da Família' },
    { id: 4503, name: "Passo Fundo - Passo Fundo", financialInstitution: 18, institution: 'Banco da Família' },
    { id: 4500, name: "Farroupilha - Farroupilha", financialInstitution: 18, institution: 'Banco da Família' },
    { id: 4501, name: "Lagoa Vermelha - Lagoa Vermelha", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Curitibanos/SC - Curitibanos/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Caçador/SC - Caçador/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Otacílio Costa/SC - Otacílio Costa/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Correia Pinto/SC - Correia Pinto/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Santa Cecília/SC - Santa Cecília/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Videira/SC - Videira/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Guarujá/Lages-SC - Guarujá/Lages-SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Ubirici/SC - Ubirici/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Anita Garibaldi/SC - Anita Garibaldi/SC", financialInstitution: 18, institution: 'Banco da Família' },
    { id: null, name: "Joaçaba/SC - Joaçaba/SC", financialInstitution: 18, institution: 'Banco da Família' }
]

const financialInstitutionList = ["Badesul", "BRDE", "Sicredi Pioneira", "Sicredi Serrana", "Sicredi Cooperucs", "Sicredi Caminho das Águas", "Sicredi Região Centro", "Unicred", "Cresol Centro Sul RS/MS", "Cresol Planalto Serra", "Sicoob - Unicoob Meridional", "Sicoob Videira", "Sicoob Creditaipu", "Sicoob Maxxicred", "Sicoob São Miguel do Oeste/SC", "Sicoob Credicapital", "Sicoob Três Fronteiras", "Banco da Família"]
export { agencies as agencias, financialInstitutionList }

