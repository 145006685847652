/* eslint-disable */
import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Input } from '@mui/material';
import { useForm } from 'react-hook-form';

const SearchMunicipalities = ({ emptyRegister, errorsFormEmpty, dataRecoverSearchMunicipalities }) => {
    const { watch } = useForm();

    const uf = 'RS'
    const [municipalities, setMunicipalities] = useState([])
    const [selectedMunicipality, setSelectedMunicipality] = useState('')
    const municipalitiesRs = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
    const filteredMunicipalities = [...municipalities.map(m => ({ id: m.id, nome: m.nome }))]

    useEffect(() => {
        const search = async () => {
            try {
                let foundMunicipalities = await fetch(municipalitiesRs)
                let municipalitiesData = await foundMunicipalities.json()
                setMunicipalities(municipalitiesData)
            } catch (error) {
                console.error(`Nada foi encontrado!: ${error}`)
            }
        }
        search()
    }, [])

    const { name } = emptyRegister
    const city = watch(name)

    useEffect(() => {
        setSelectedMunicipality(city)
    }, [city])

    return (
        <>
            <FormControl variant="filled" fullWidth className="fieldStyle" style={{backgroundColor:'#E8E8E8'}}>
                <InputLabel>Cidade</InputLabel>
                <Input
                    {...emptyRegister}
                    className='inputDataList'
                    type='text' inputProps={{ list: 'search' }}
                    style={{ textAlign: 'center', paddingLeft: '10px', height: '56px' }}
                // onChange={handleInputChange}
                // value={selectedMunicipality}
                />
                <datalist id='search'>
                    {filteredMunicipalities.map(municipality => (<option key={municipality.id}>{municipality.nome}</option>))}
                </datalist>
                <div style={{ marginTop: '7px' }}>
                    {errorsFormEmpty}
                </div>
            </FormControl>
        </>
    )
}

export default SearchMunicipalities