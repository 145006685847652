/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import {
  maskCnpj,
  maskPhoneNumber,
  maskCep,
  maskCurrency,
  maskInscricaoEstadual,
} from '../utils/masks'
import { financialInstitutionList } from '../../shared/formAgencyData';
import SearchMunicipalities from '../SearchMunicipalities';

import { agencias } from '../../shared/formAgencyData';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

const PjForm = ({ watch, register, setValue, errors, onRefChange }) => {
  const { control } = useForm();

  let cnpj = watch('Cnpj')
  useEffect(() => {
    setValue('Cnpj', maskCnpj(cnpj))
  }, [cnpj])

  let inscricaoEstadual = watch('InscricaoEstadual')
  useEffect(() => {
    setValue('InscricaoEstadual', maskInscricaoEstadual(inscricaoEstadual))
  }, [inscricaoEstadual])

  const cepValue = watch('CepDaEmpresa')
  useEffect(() => {
    setValue('CepDaEmpresa', maskCep(cepValue))
  }, [cepValue])

  const phoneValue = watch('TelefoneDaEmpresa')
  useEffect(() => {
    setValue('TelefoneDaEmpresa', maskPhoneNumber(phoneValue))
  }, [phoneValue])

  const contactPhoneNumber = watch('FoneParaContato')
  useEffect(() => {
    setValue('FoneParaContato', maskPhoneNumber(contactPhoneNumber))
  }, [contactPhoneNumber])

  const currencyValue = watch('FaturamentoAnual')
  useEffect(() => {
    setValue('FaturamentoAnual', maskCurrency(currencyValue))
  }, [currencyValue])

  const [ativoInscricaoEstadual, setAtivaInscricaoEstadual] = useState('')
  const handleAtivadadeDaEmpresa = (e) => {
    setAtivaInscricaoEstadual(e.target.value === "7");
    if (cnpj)
      setValue('Cnpj', maskCnpj(""))
    else if (inscricaoEstadual)
      setValue('InscricaoEstadual', maskInscricaoEstadual(""))
  };

  const [sortedList] = useState([...financialInstitutionList.sort()])
  let filtered = [], listAgencies = []
  const handleFilteredAgency = (financialInstitutionNumber) => {
    filtered = agencias.filter((i) => i.id !== null)
    if (filtered.length > 0) {
      listAgencies = filtered.map(a => ({ value: a.id, label: a.name, financialInstitution: a.financialInstitution })).filter(c => c.financialInstitution === financialInstitutionNumber)
    }
  }

  const selectedRef = useRef();
  useEffect(() => {
    onRefChange(selectedRef)
  }, [onRefChange])

  const clearAllAgencies = () => {
    selectedRef.current.clearValue();

  }

  const AgenciaAssociada = watch('AgenciaAssociada')
  
  switch (AgenciaAssociada) {
    // case "":
    //   ""
    //   break;
    case '0':
      handleFilteredAgency(0)
      break;
    case '1':
      handleFilteredAgency(7)
      break;
    case '2':
      handleFilteredAgency(18)
      break;
    case '3':
      handleFilteredAgency(12)
      break;
    case '4':
      handleFilteredAgency(24)
      break;
    case '5':
      handleFilteredAgency(25)
      break;
    case '6':
      handleFilteredAgency(29)
      break;
    case '7':
      handleFilteredAgency(27)
      break;
    case '8':
      handleFilteredAgency(28)
      break;
    case '9':
      handleFilteredAgency(31)
      break;
    case '10':
      handleFilteredAgency(30)
      break;
    case '11':
      handleFilteredAgency(26)
      break;
    case '12':
      handleFilteredAgency(21)
      break;
    case '13':
      handleFilteredAgency(20)
      break;
    case '14':
      handleFilteredAgency(5)
      break;
    case '15':
      handleFilteredAgency(23)
      break;
    case '16':
      handleFilteredAgency(19)
      break;
    case '17':
      handleFilteredAgency(9)
      break;
  }



  return (
    <div className="pjForm grid">
      <div className="Empresa">
        <TextField
          {...register('RazaoSocial', { required: true })}
          label="Razão Social"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.RazaoSocial && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="CNPJ"
        style={{ display: !ativoInscricaoEstadual ? 'block' : 'none' }}
      >
        <TextField
          {...register('Cnpj', { required: !ativoInscricaoEstadual }
          )
          }
          label="CNPJ"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
          inputProps={{ maxLength: 18 }}
          value={cnpj}
        />
        {errors.Cnpj && <span className="erroForm">&gt; Obrigatório</span>}
      </div>

      <div className="InscricaoEstadual" style={{ display: ativoInscricaoEstadual ? 'block' : 'none' }}>
        <TextField
          {...register('InscricaoEstadual', { required: ativoInscricaoEstadual })}
          label="Inscrição Estadual"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
          inputProps={{ maxLength: 11 }}
          value={inscricaoEstadual}
        />
        {errors.InscricaoEstadual && <span className="erroForm">&gt; Obrigatório Agricultor</span>}
      </div>

      <div className="Telefone">
        <TextField
          {...register('TelefoneDaEmpresa', { required: true })}
          label="Telefone"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.TelefoneDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="CEP">
        <TextField
          {...register('CepDaEmpresa', { required: true })}
          label="CEP"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.CepDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Endereco">
        <TextField
          {...register('EnderecoDaEmpresa', { required: true })}
          label="Endereço"
          variant="filled"
          className="fieldStyle"
        />
        {errors.EnderecoDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Complemento">
        <TextField
          {...register('ComplementoDaEmpresa', { required: true })}
          label="Complemento"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.ComplementoDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Bairro">
        <TextField
          {...register('BairroDaEmpresa', { required: true })}
          label="Bairro"
          variant="filled"
          className="fieldStyle"
        />
        {errors.BairroDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <Box sx={{ maxWidth: 540 }} className="Cidade">
        <SearchMunicipalities

          emptyRegister={
            {
              ...register('CidadeDaEmpresa',
                { required: true })
            }
          }
          errorsFormEmpty={
            errors.CidadeDaEmpresa && (
              <span className="erroForm">&gt; Obrigatório</span>
            )
          }
        />
      </Box>

      <Box sx={{ maxWidth: 540 }} className="Estado">
        <FormControl variant="filled" fullWidth className="fieldStyle">
          <TextField
            {...register('EstadoDaEmpresa', { required: true })}
            readonly={true}
            label="UF"
            variant="filled"
            value={"RS"}
          />
        </FormControl>
      </Box>

      <div className="E-mail">
        <TextField
          {...register('EmailDaEmpresa', { required: true })}
          label="E-mail"
          variant="filled"
          className="fieldStyle"
        />
        {errors.EmailDaEmpresa && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="FoneParaContato">
        <TextField
          {...register('FoneParaContato', { required: true })}
          label="Fone para Contato"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.FoneParaContato && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="NomeParaContato">
        <TextField
          {...register('NomeParaContato', { required: true })}
          label="Nome para Contato"
          autoComplete="none"
          variant="filled"
          className="fieldStyle"
        />
        {errors.NomeParaContato && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <div className="Faturamento">
        <TextField
          {...register('FaturamentoAnual', { required: true })}
          label="Faturamento dos últimos 12 meses"
          variant="filled"
          className="fieldStyle"
        />
        {errors.FaturamentoAnual && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </div>

      <FormControl className="Atividade radioStyle BorderRadiusAtividade">
        <FormLabel style={{ paddingTop: '15px', paddingLeft: '15px', paddingRight: '15px' }}>Atividade</FormLabel>
        <RadioGroup row={false} defaultValue={1}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <FormControlLabel
              {...register('AtividadeDaEmpresa', { required: true })}
              value="1"
              control={<Radio />}
              label="Indústria"
              onChange={handleAtivadadeDaEmpresa}
            />
            <FormControlLabel
              {...register('AtividadeDaEmpresa', { required: true })}
              value="2"
              control={<Radio />}
              label="Comércio"
              onChange={handleAtivadadeDaEmpresa}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <FormControlLabel
              {...register('AtividadeDaEmpresa', { required: true })}
              value="3"
              control={<Radio />}
              label="Serviço"
              onChange={handleAtivadadeDaEmpresa}
            />
            <FormControlLabel
              {...register('AtividadeDaEmpresa', { required: true })}
              value="7"
              control={<Radio />}
              label="Agricultor"
              onChange={handleAtivadadeDaEmpresa}
            />
          </div>
        </RadioGroup>
      </FormControl>

      <FormControl className="Porte radioStyle BorderRadiusPorte">
        <FormLabel id="FormLabelPordeDaEmpresa">Porte da Empresa</FormLabel>
        <RadioGroup row defaultValue="MEI">
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="MEI"
            control={<Radio />}
            label="MEI"
          />
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="ME"
            control={<Radio />}
            label="ME"
          />
          <FormControlLabel
            {...register('PorteDaEmpresa', { required: true })}
            value="EPP"
            control={<Radio />}
            label="EPP"
          />
        </RadioGroup>
      </FormControl>

      {/* Instituições financeiras */}
      <FormControl className='LinhasDeCreditosAssociadas customStyles' variant="filled" fullWidth>
        <label>
          <span style={{ color: '#fff' }}>Selecione uma instituição financeira</span>
          <select
            {...register('AgenciaAssociada', { required: true })}
            className='selectedOptionPjForm'
            
            onChange={(event) => {
              setValue('AgenciaAssociada', event.target.value)
              clearAllAgencies()
            }}
          >
            <option selected disabled value=""></option>
            {sortedList.map((item, i) => {
              return (<option
                style={{ padding: '5px' }}
                value={i.toString()}
              >
                {item}
              </option>)
            })
            }
          </select>
        </label>
        {errors.AgenciaAssociada && (
          <span className="erroForm">&gt; Obrigatório</span>
        )}
      </FormControl>

      {/** Agências */}
      <div className='TipoDeAgencia'>
        <Controller
          {...register('Agencia', { required: 'Este campo é obrigatório' })}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <div
                style={{ backgroundColor: '#1A629A', padding: '10px 35px', borderRadius: '40.6px' }}
              >
                <FormLabel style={{ color: '#fff', textAlign: 'center' }}>
                  Selecione a agência onde possui ou deseja abrir conta
                </FormLabel>
                <Select
                  {...field}
                  {...fieldState}
                  ref={selectedRef}
                  options={listAgencies}
                  placeholder=""

                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#fff',
                      boxShadow: 'none',
                      position: 'relative',
                      zIndex: '1000',
                      height: "28px !important",
                      maxWidth: '468.6px',
                      margin: 'auto'
                    })
                  }}
                  onChange={(e) => setValue('Agencia', e)}
                />
                {errors?.Agencia && <span className='erroForm'>&gt;{errors.Agencia.message}</span>}
              </div>

            </>
          )}
        />
      </div>
    </div >
  )
}

export default PjForm;

